import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import bootstrap from "../components/bootstrap.module.css"
import SEO from "../components/seo"
import Breadcrumbs from "../components/breadcrumbs"

export default function PageReklama({ data }) {

  const row = data.mysqlPage
  const seo = data.seo

  const clients = [
    { src: 'https://static.fintek.pl/themes/fintekNew/assets/loga/walutomat.png', alt: 'walutomat', },
    { src: 'https://static.fintek.pl/themes/fintekNew/assets/loga/visa.png', alt: 'visa', },
    { src: 'https://static.fintek.pl/themes/fintekNew/assets/loga/tpay.png', alt: 'tpay', },
    { src: 'https://static.fintek.pl/themes/fintekNew/assets/loga/twisto.png', alt: 'twisto', },
    { src: 'https://static.fintek.pl/themes/fintekNew/assets/loga/link4.png', alt: 'link4', },
    { src: 'https://static.fintek.pl/themes/fintekNew/assets/loga/vivus.png', alt: 'vivus', },
    { src: 'https://static.fintek.pl/themes/fintekNew/assets/loga/nest.png', alt: 'nest', },
    { src: 'https://static.fintek.pl/themes/fintekNew/assets/loga/kontomatik.png', alt: 'kontomatik', },
    { src: 'https://static.fintek.pl/themes/fintekNew/assets/loga/loando-group.png', alt: 'loando', },
    { src: 'https://static.fintek.pl/themes/fintekNew/assets/loga/summit.png', alt: 'summit', },
    { src: 'https://static.fintek.pl/themes/fintekNew/assets/loga/ecu.png', alt: 'ecu', },
  ];

  return (
    <Layout>

      <div className="adv">
      <SEO canonical={ seo && seo.canonical ? seo.canonical : 'https://fintek.pl/' + row.post_name + '/'} title={ seo && seo.title ? seo.title : row.post_title } description={seo ? seo.description : ''} robots={seo ? [(seo.is_robots_noindex?'noindex':'index'),(seo.is_robots_nofollow?'nofollow':'follow')].join(', ') : 'index, follow'} />  

        <Breadcrumbs post={row} />

        <h1 className={bootstrap.mb3}>{ row.post_title }</h1>
        <hr className={bootstrap.mb4} />

        <section className={`font-serif-light ${bootstrap.mb5}`}>
          <h3 className={`normal-title ${bootstrap.mb5}`}>Prowadzisz biznes fintechowy, który chcesz wypromować? A może szukasz nowych możliwości dotarcia do klientów? Z naszym wsparciem Twoje projekty nabiorą rozpędu!</h3>
          
          <div className={`${bootstrap.row} ${bootstrap.alignItemsCenter} ${bootstrap.justifyContentCenter}`}>
            <div className={`${bootstrap.colMd6} ${bootstrap.colLg12} ${bootstrap.colXl5}`}>
              <img className={`img ${bootstrap.mxAuto}`} src="https://static.fintek.pl/uploads/2023/12/piotr-suchodolski.webp" alt="Piotr Suchodolski"  />
            </div>

            <div className={`${bootstrap.colMd6} ${bootstrap.colLg12} ${bootstrap.colXl7}`}>
              <div className={`center ${bootstrap.mt4} ${bootstrap.mtMd0} ${bootstrap.mtLg4} ${bootstrap.mtXl0} ${bootstrap.textLgCenter} ${bootstrap.textXlLeft}`}>
                
                <div className={`${bootstrap.row} ${bootstrap.justifyContentCenter} ${bootstrap.justifyContentMdStart} ${bootstrap.justifyContentLgCenter} ${bootstrap.justifyContentXlStart} ${bootstrap.alignItemsCenter} ${bootstrap.noGutters} ${bootstrap.mb2} ${bootstrap.mbLg3}`}>
                  <div className={bootstrap.colSmAuto}>
                    <img src="https://static.fintek.pl/themes/fintekNew/assets/img/phone-big.svg" alt="" />
                  </div>
                  <div className={bootstrap.colSmAuto}>
                    <h2 className={`title-person-name ${bootstrap.mt2} ${bootstrap.mb0} ${bootstrap.mlSm3}`}>Piotr Suchodolski</h2>
                  </div>
                </div>
                
                <p className="job">Public Relations and Communication Expert Klang Media / Fintek</p>
                <p>p.suchodolski@klangmedia.pl</p>
                <p>tel. 732 757 275</p>

                <a className={`purple-btn ${bootstrap.mxLgAuto} ${bootstrap.mxXl0}`} href="mailto:p.suchodolski@klangmedia.pl">Napisz do mnie</a>
              </div>
            </div>
          </div>
        </section>

        <section className={`center font-serif-light gray-bg ${bootstrap.mb5}`}>
          <div className={`${bootstrap.row} ${bootstrap.justifyContentCenter} ${bootstrap.alignItemsCenter}`}>
            <div className={`${bootstrap.col10} ${bootstrap.colSm8} ${bootstrap.colXl6} ${bootstrap.pbMd4}`}>
              <div className={`${bootstrap.plLg3} ${bootstrap.plXl5} ${bootstrap.pt3}`}>
                <h2 className="title">Chcesz, żeby o Twoim biznesie było głośno?</h2>
                <p className={bootstrap.my4}>Dzięki naszemu wsparciu i autorskim projektom tworzonym przez zespół Fintek.pl zyskasz wiele możliwości promowania swojej marki.</p>
              </div>
            </div>
            <div className={bootstrap.colXl6}>
              <img className="img" src="https://static.fintek.pl/themes/fintekNew/assets/img/ads_img_01.jpg" alt="smart" />
            </div>
          </div>
        </section>

        <section className={`center font-serif-light ${bootstrap.mb5}`}>
          <div className={`${bootstrap.row} ${bootstrap.alignItemsCenter} ${bootstrap.justifyContentCenter}`}>
            <div className={bootstrap.colXl6}>
              <img className="img" src="https://static.fintek.pl/themes/fintekNew/assets/img/ads_img_02.jpg" alt="notebook" />
            </div>
            <div className={`${bootstrap.col10} ${bootstrap.colSm8} ${bootstrap.colXl6} ${bootstrap.mt3} ${bootstrap.mtXl0}`}>
              <h2 className="title">Napisz lub zadzwoń już dziś!</h2>
              <p className="serif">Cykl tematyczny, videopodcast, a może widget promujący Twoje rozwiązanie? Przedstaw nam swoje potrzeby, a my dobierzemy narzędzia, które spełnią Twoje oczekiwania.</p>
            </div>
          </div>
        </section>

        <section className={`center font-serif-light ${bootstrap.textCenter} ${bootstrap.mb5}`}>
          <h2 className={`title ${bootstrap.mb4}`}>Nasi zadowoleni klienci:</h2>
          <div className={`${bootstrap.row} ${bootstrap.justifyContentCenter} ${bootstrap.pxXl5} ${bootstrap.mxXl4}`}>
            {clients.map((client, i) => (
              <div key={i} className={`${bootstrap.col6} ${bootstrap.colSmAuto} client-logo`}>
                <img src={client.src} alt={client.alt} />
              </div>
            ))}
          </div>
        </section>
      </div>

    </Layout>
  )
  
}

export const query = graphql`
query PageQueryReklama($slug: String, $id: Int) {
  mysqlPage: mysqlPage(post_name: {eq: $slug}) {
    id
    post_date
    post_title
    post_content
    post_name
  }
  seo: mysqlIndexable(object_type: {eq: "post"}, object_id: {eq: $id}) {
    title
    description
    object_id
    object_type
    permalink
    breadcrumb_title
    canonical
    is_robots_noindex
    is_robots_nofollow
  }  
}
`
